<template>
  <i :class="{
    'fa-check-circle': tick,
    'fa-times-circle': !tick
  }" class="fa"></i>
</template>

<script setup>
const props = defineProps(
    {
      tick: {
        type: Boolean,
        required: true
      }
    }
)
</script>

<style>
.fa-check-circle {
  color: green;
}

.fa-times-circle {
  color: red;
}
</style>
