<template>
  <div :class="classResolver(classes)" class="defaultWrapper">
    <DynamicComponents :components="components"/>
  </div>
</template>
<script setup>
import commonProps from '@/_core/components/_properties/common'
import DynamicComponents from "@/_core/components/partials/DynamicComponents.vue";
import classResolver from "@/_core/services/classResolver";

const props = defineProps({
  ...commonProps,
  components: {
    type: Array,
    default: () => ([]),
  },
});
</script>
