<template>
  <component
      :is="component.name"
      v-for="component in components"
      :key="component.id"
      :classes="component.classes"
      :componentData="component"
      :componentId="component.id"
      :components="component.components"
      :customData="customData ?? {}"
      v-bind="component.props"
  />
</template>

<script setup>

const props = defineProps({
  components: {
    type: Array,
    required: true,
  },
  customData: {
    type: Object,
    required: false,
    default: {},
  },
});

</script>
