<template>
    <div :class="classes" class="imageTile">
        <a v-if="href" :href="href">
            <img :src="imgSrc" :title="title" alt=""/>
        </a>
        <img v-else :src="imgSrc" :title="title" alt=""/>
    </div>
</template>

<script setup>
import commonProps from '@/_core/components/_properties/common'

defineProps({
  ...commonProps,
  imgSrc: {
    type: String,
    required: true
  },
  title: {
    type: String,
    default: ""
  },
  href: {
    type: String,
    default: ""
  }
})

</script>
