<template>
  <i v-if="icon" :class="['fal', 'fa-fw', `fa-${icon}`]"></i>
  {{ title }}

</template>

<script setup>
const props = defineProps({
  icon: {
    type: String,
    default: ''
  },
  title: {
    type: String,
    required: true
  }
})
</script>
