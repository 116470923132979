<template>
  <aside :style="{backgroundColor: rgb}" class="trafficLightDisplay">
    <header class="trafficLightDisplay__header">
      <slot name="header">{{ header }}</slot>
    </header>
    <div class="trafficLightDisplay__content">
      <slot name="content">{{ content }}</slot>
    </div>
    <footer class="trafficLightDisplay__footer">
      <slot name="footer">{{ footer }}</slot>
    </footer>
  </aside>

</template>

<script setup>
defineProps({
  rgb: {
    type: String,
    default: 'rgb(255, 255, 255)',
  },
  header: {
    type: String,
    default: '',
  },
  content: {
    type: String,
    default: '',
  },
  footer: {
    type: String,
    default: '',
  },
})
</script>

<style lang="scss">
.trafficLightDisplay {
  border-radius: 5px;
  text-align: center;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.6);

  > * {
    padding: 1rem;
  }

  .big {
    font-size: 2rem;
    color: #fff;
    font-weight: bold;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.6);;
  }

  &__content {
    border-width: 2px 0;
    border-style: solid;
    border-color: #fff;
  }
}
</style>
