<template>
    <nav id="leftSidebar" :class="classes">
        <DynamicComponents :components="components"/>
    </nav>
</template>

<script setup>
import {defineProps} from 'vue';
import DynamicComponents from "@/_core/components/partials/DynamicComponents.vue";
import commonProps from '@/_core/components/_properties/common'

defineProps({
  ...commonProps,
  components: {
    type: Array,
    default: () => ([]),
  },
});
</script>

<style lang="scss">
#leftSidebar {
  padding: 0;
  min-height: 100vh;
  background: $structure--leftsidebar--background;
  flex: 0 0 320px;

  .imageTile {
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    -webkit-box-shadow: 0 0 28px 0 rgba(0, 0, 0, 0.13);
    box-shadow: 0 0 28px 0 rgba(0, 0, 0, 0.13);

    img {
      margin-left: auto;
      margin-right: auto;
      display: flex;
      max-width: 130px
    }
  }

  .account {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #333;
    position: relative;
    overflow: hidden;
    height: 9.53rem;
    background-size: cover;
    background-image: $structure--html--background;

    .user-image {
      padding-left: 40px;
      padding-right: 40px;

      i {
        color: #ccc;
        font-weight: 300;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        display: inline-block;
        width: 100%;
        text-align: center;
      }
    }

    .user-name {
      padding: 15px;
      text-transform: capitalize;
      color: $c-white;
      font-weight: 500;

      span {
        font-weight: bold;
      }
    }

    .user-role {
      color: $c-white;
    }
  }

  .menu-title {
    padding: 2rem 2rem 1rem 2rem;
    color: white;
    font-weight: 400;
  }

  .group {
    a {
      padding: 0.8125rem 2rem;
      color: #ffffff;
      font-size: 0.875rem;
    }

    ul {
      border: none;
      background-color: darken($brand--c1, 10%);
      padding: 0;

      li {
        padding: 0;

        a {
          padding: 0.8125rem 2rem 0.8125rem 4rem;
        }
      }
    }

    .menu-group {
      > a {
        &.router-link-exact-active {
          background-image: linear-gradient(90deg, darken($brand--c1, 10%) 4px, rgba(0, 150, 136, 0) 0),
          linear-gradient(90deg, rgba(0, 0, 0, 0.26) 1px, transparent 0);
        }
      }

      > ul {
        padding: 0;

        li {
          position: relative;

          ul {
            padding: 10px 0;

            &:before {
              content: "";
              display: block;
              position: absolute;
              z-index: 1;
              left: 2.5625rem;
              top: 0;
              bottom: 0;
              border-left: 1px solid $brand--c1;
            }

            li {
              &:after {
                content: "";
                display: block;
                position: absolute;
                width: 0.4rem;
                height: 0.4rem;
                background-color: $brand--c1;
                left: 2.4rem;
                top: calc(50% - 0.3rem);
                border: 1px solid #333;
                border-radius: 50%;
                z-index: 1;
              }

              &:hover {
                background-color: darken($brand--c1, 20%);

                &:after {
                  border: 1px solid $brand--c1;
                }
              }

              i {
                display: none;
              }
            }
          }
        }
      }
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  @media screen and (max-width: 1290px) {
    flex: 0 0 80px;
    width: 80px;
    .imageTile {
      img {
        display: none;
      }
    }
    .parentLink {
      display: none;
    }
    .menu-title {
      display: none;
    }
    .group {
      a {
        padding: 0;
        align-items: center;
        justify-content: center;
        display: flex;
        height: 50px;
      }

      i {
        margin-right: 0;
      }
    }
  }
}
</style>
