<template>
  <div :class="`loadingWrapper--${scope}`" class="loadingWrapper">
    <div class="loadingWrapper__text">
      {{ text }}
    </div>
    <div class="loadingWrapper__spinner">
      <i class="fa fa-spin fa-2x fa-spinner"></i>
    </div>
  </div>
</template>
<script setup>
defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
  scope: {
    type: String,
    default: 'global',
  },
  text: {
    type: String,
    default: 'Loading',
  }
})

</script>
<style lang="scss">
.loadingWrapper {
  padding: 10px;
  z-index: 999;
  color: $c-black;
  position: fixed;
  inset: 0;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.5);
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(3px);

  &__text {
    margin-bottom: 12px;
  }
}
</style>
