<template>
  <ul class="historyList__items">
    <li v-for="item in history" :key="item.created" class="visitHistory__item">
      <span class="historyList__date">{{ new Date(item.created).toLocaleString() }}</span>
      -
      <span class="historyList__action">{{ item.action }}</span>
      by
      <span class="historyList__user">{{ item.user }}</span>
    </li>
  </ul>
</template>

<script setup>
defineProps({
  history: {
    type: Array,
    required: true,
  },
})

</script>
<style lang="scss">
.historyList {
  &__items {
    list-style: none;
    padding: 0;
  }

  &__date {
    font-style: italic;
  }

  &__action {
    font-weight: bold;
  }

}
</style>
