<template>
    <div class="account text-center">
        <div v-if="imgSrc" class="user-image">
            <img :src="imgSrc" alt="">
        </div>
        <div class="user-details">
            <div class="user-name">
                {{ name }}
            </div>
            <div class="user-role">
                {{ role }}
            </div>
        </div>
    </div>
</template>
<script setup>
import {defineProps} from 'vue';

defineProps({
  name: {
    type: String,
    required: true,
  },
  role: {
    type: String,
    required: true,
  },
  imgSrc: {
    type: String,
    default: '',
  },
})
</script>
