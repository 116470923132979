<template>
  <div class="apiErrorDisplay">
    <h1>API Error</h1>

    <h2>Message</h2>
    <code>{{ message }}</code>

    <h2>Location</h2>
    <code>{{ file }}::{{ line }}</code>

    <h2>Trace</h2>
    <div>
      <details v-for="{ file, line, class: cls, function: fn} in trace">
        <summary><code>{{ file }}::{{ line }}</code></summary>
        <template v-if="cls">
          <dt>Class</dt>
          <dd><code>{{ cls }}<span v-if="fn">::{{ fn }}</span></code></dd>
        </template>

        <template v-else-if="fn">
          <dt>Function</dt>
          <dd><code>{{ fn }}</code></dd>
        </template>

      </details>
    </div>
  </div>
</template>

<script setup>
defineProps({
  message: {
    type: String,
    required: true
  },
  file: {
    type: String,
    required: true
  },
  line: {
    type: Number,
    required: true
  },
  trace: {
    type: Array,
    required: true
  }
})
</script>

<style lang="scss" scoped>
.apiErrorDisplay {
  background-color: rgba(0, 0, 0, 0.8);
  color: #f00;
  padding: 1rem;
  margin: 1rem;
  border-radius: 1rem;

  h2 {
    margin-top: 1rem;
  }

  code {
    color: inherit;
  }
}
</style>
