<template>
  <DynamicComponents :components="components"/>
</template>
<script setup>
import DynamicComponents from "@/_core/components/partials/DynamicComponents.vue";

const props = defineProps({
  components: {
    type: Array,
    required: true,
  },
});
</script>
