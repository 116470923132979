<template>
  <ContactPanel :entity-id="entityId" class="companyWarrantyContact"/>
</template>

<script setup>
import ContactPanel from "@/components/DashboardPanel/_ContactPanel.vue";

const props = defineProps({
  entityId: {
    type: Number,
    required: true,
  },
});

</script>
